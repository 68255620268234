import React, { memo } from 'react';
import Hotkeys from 'react-hot-keys';
const MsgBox = memo(({ title, yesFn, noFn }) => {
  return (
    <div className="msg-box">
      <div className="msg-head">Confirm</div>
      <div className="msg-main">
        <Hotkeys
          keyName="esc,enter"
          onKeyDown={key => {
            if (key === 'enter') {
              yesFn();
            } else if (key === 'esc') {
              noFn();
            }
          }}
        >
          <div className="msg-title">{title}</div>
          <button className="msg-yes msg-btn" onClick={yesFn}>
            YES
          </button>
          <button className="msg-no msg-btn" onClick={noFn}>
            NO
          </button>
        </Hotkeys>
      </div>
    </div>
  );
});

export default memo(MsgBox);
