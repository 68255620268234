import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import './AdminApp.css';
import Menubar from '../common/Menubar';
import ProjectsGrid from '../common/ProjectsGrid';
import ProjectSummary from './ProjectSummary';
import ProjectPage from './ProjectPage';
import InspectionPage from './InspectionPage';
import LoginPage from './LoginPage';

import * as config from '../config';
export default class AdminApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check: true,
    };
    this.setCheck = this.setCheck.bind(this);
  }
  async setCheck() {
    const check = !this.state.check;
    this.setState({ check });
  }
  render() {
    return (
      <Menubar active="admin">
        <Switch>
          <Route
            exact
            path="/admin/"
            render={() => (
              <>
                {!this.state.check ? (
                  <>
                    <span className="hide-opt" onClick={this.setCheck}>
                      Expand Statistics
                    </span>
                    <ProjectSummary title="이미지 라벨링 현황" />
                  </>
                ) : (
                  <span className="hide-opt" onClick={this.setCheck}>
                    Collapse Statistics
                  </span>
                )}

                <ProjectsGrid
                  linkPrefix="/admin/"
                  title="프로젝트 목록"
                  showCreateButton={config.ALLOW_EDIT}
                />
              </>
            )}
          />
          <Route exact path="/admin/login" component={LoginPage} />
          <Route
            exact
            path="/admin/inspection/:projectId/:projectHash"
            component={InspectionPage}
          />
          <Route
            path="/admin/:projectId/:projectHash"
            component={ProjectPage}
          />
        </Switch>
      </Menubar>
    );
  }
}
